import React from 'react';
import { PostHogProvider as PostHogProviderBase } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

const publicPosthogKey =
  process.env.NODE_ENV === 'production'
    ? 'phc_xZmf5mR4vrk1KitDGvCv2sYhT0UDlqnkVNq44HjRuXc' // PV Marketplace
    : 'phc_GPV3fSvIW9KAs0RyMLN7O6SUeVwsoYgzrBBcIy91DWj'; // PV Marketplace (dev)

const options: Partial<PostHogConfig> = {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'always',
};

export const PostHogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <PostHogProviderBase options={options} apiKey={publicPosthogKey}>
      {children}
    </PostHogProviderBase>
  );
};
