import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAvailability } from '../../../../providers/AvailabilityProvider';

export const ActionButton = ({
  venueSlug,
  spaceSlug,
  onInvalid,
}: {
  venueSlug: string;
  spaceSlug: string;
  onInvalid: () => void;
}) => {
  const navigate = useNavigate();
  const { startDate, startOffset, endOffset, origin } = useAvailability();
  const valid = startDate && startOffset && endOffset;
  const navigateToBooking = () => {
    navigate(`/venues/${venueSlug}/spaces/${spaceSlug}/bookings/new`, {
      state: {
        startDate: startDate?.toISOString() || '',
        startOffset: startOffset || '',
        endOffset: endOffset || '',
        origin: origin || '',
      },
    });
  };
  const handleClick = () => {
    if (valid) {
      navigateToBooking();
    } else {
      onInvalid();
    }
  };

  const buttonText = valid ? 'Request to Book' : 'Check Availability';
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      size="large"
      sx={{
        padding: '12px 28px',
        fontSize: '16px',
      }}
    >
      {buttonText}
    </Button>
  );
};
