import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Zap } from 'react-feather';
import { Dayjs } from 'dayjs';
import { gql, useQuery } from '@apollo/client';

import type { Venue } from '../../../../types/models/venue_types';
import type { Space } from '../../../../types/models/space_types';

import { DatePicker } from './DatePicker';
import { ActionButton } from './ActionButton';
import { StartTimePicker } from './StartTimePicker';
import { EndTimePicker } from './EndTimePicker';
import { PVRed } from '@pv/common/colors';
import { useAvailability } from '../../../../providers/AvailabilityProvider';
const spaceBookingTimesQuery = gql`
  query SpaceBookableTimes(
    $venueSlug: String!
    $spaceSlug: String!
    $eventDate: ISO8601Date!
  ) {
    spaceBookableTimes(
      venueSlug: $venueSlug
      spaceSlug: $spaceSlug
      eventDate: $eventDate
    ) {
      availability {
        offset
        available
      }
    }
  }
`;

const spaceBookingEndTimesQuery = gql`
  query SpaceBookableEndTimes(
    $venueSlug: String!
    $spaceSlug: String!
    $eventDate: ISO8601Date!
    $startOffset: Int!
  ) {
    spaceBookableEndTimes(
      venueSlug: $venueSlug
      spaceSlug: $spaceSlug
      eventDate: $eventDate
      startOffset: $startOffset
    ) {
      availability {
        offset
        available
      }
    }
  }
`;

const AvailableForExpressBookChip = () => (
  <Stack
    sx={{
      background: 'linear-gradient(94deg, #FFE6CF 4.44%, #FFD2F8 95.56%)',
      padding: '8px 16px',
      borderRadius: '20px',
      flexDirection: 'row',
      gap: '8px',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Zap color={PVRed} />
    <Typography variant="subtitle2">Available For Express Book</Typography>
  </Stack>
);

export const AvailabilityForm = ({
  space,
  venue,
}: {
  space: Space;
  venue: Venue;
}) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);

  const {
    startDate,
    startOffset,
    endOffset,
    setStartDate,
    setStartOffset,
    setEndOffset,
    setOrigin,
  } = useAvailability();

  const { data: spaceBookableTimesData } = useQuery(spaceBookingTimesQuery, {
    variables: {
      spaceSlug: space.slug,
      venueSlug: venue.slug,
      eventDate: startDate,
    },
    skip: !startDate,
  });

  const { data: spaceBookableEndTimesData } = useQuery(
    spaceBookingEndTimesQuery,
    {
      variables: {
        spaceSlug: space.slug,
        venueSlug: venue.slug,
        eventDate: startDate,
        startOffset: startOffset,
      },
      skip: !startOffset,
    },
  );

  const availability =
    spaceBookableTimesData?.spaceBookableTimes.availability || [];

  const endTimeAvailability =
    spaceBookableEndTimesData?.spaceBookableEndTimes.availability || [];

  const availableForExpressBook =
    space.expressBookEnabled &&
    venue.menu?.menuItems &&
    venue.menu.menuItems.length > 0;

  React.useEffect(() => {
    if (availableForExpressBook) {
      setOrigin('express_book');
    } else {
      setOrigin('availability_check');
    }
  }, [setOrigin, availableForExpressBook]);

  return (
    <form role="search">
      <Stack spacing={3}>
        <DatePicker
          hoursOfOperation={venue.hoursOfOperation}
          minLeadTimeDays={venue.expressBookSettings.minLeadTimeDays}
          maxLeadTimeDays={venue.expressBookSettings.maxLeadTimeDays}
          open={datePickerOpen}
          onOpen={() => {
            setDatePickerOpen(true);
          }}
          onClose={() => {
            setDatePickerOpen(false);
          }}
          value={startDate}
          onChange={(val: Dayjs | null) => {
            setStartDate(val);

            // Clear the start/end time when the date selection changes
            setStartOffset(null);
            setEndOffset(null);
          }}
        />

        <Stack spacing={3} direction={'row'}>
          <StartTimePicker
            availability={availability}
            open={startTimeOpen}
            onChange={(e) => setStartOffset(Number(e.target.value))}
            onClose={() => {
              setStartTimeOpen(false);
            }}
            onOpen={() => {
              setStartTimeOpen(true);
            }}
            value={startOffset}
            disabled={!startDate}
          />
          <EndTimePicker
            availability={endTimeAvailability}
            open={endTimeOpen}
            onOpen={() => {
              setEndTimeOpen(true);
            }}
            onClose={() => {
              setEndTimeOpen(false);
            }}
            startOffset={startOffset}
            value={endOffset}
            onChange={(e) => setEndOffset(Number(e.target.value))}
          />
        </Stack>

        <ActionButton
          venueSlug={venue.slug}
          spaceSlug={space.slug}
          onInvalid={() => {
            // Go to the first empty field in the form

            if (!startDate) {
              return setDatePickerOpen(true);
            }

            if (!startOffset) {
              return setStartTimeOpen(true);
            }

            if (!endOffset) {
              return setEndTimeOpen(true);
            }
          }}
        />
        {availableForExpressBook && <AvailableForExpressBookChip />}
      </Stack>
    </form>
  );
};
