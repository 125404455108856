import React, { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ServerStateProvider, ThemeProvider } from '@pv/common/providers';
import { apiHost } from '@pv/common/api';
import { ErrorBoundary } from '@pv/common/components';

import './App.css';
import { SearchPageLayout } from './pages/Search/components/SearchPageLayout';
import { SearchResultsPage } from './pages/Search';
import { OrganizationSpacesPage } from './pages/Organizations/OrganizationSpacesPage';
import { VenueSpacesPage } from './pages/Venues/VenueSpacesPage';
import { MarketplaceLayout } from './components/MarketplaceLayout';
import { VenueBookingsPage } from './pages/ExpressBook/Step1/VenueBookingsPage';
import { SelectMenuPage } from './pages/ExpressBook/Step2/SelectMenuPage';
import { SpacePage } from './pages/Venues/Spaces/SpacePage';
import { MarketplaceEventSuccessPage } from './pages/ExpressBook/Step1/MarketplaceEventSuccessPage';
import { ExpressBookCheckoutPage } from './pages/ExpressBook/Step3/ExpressBookCheckoutPage';
import { ExpressBookConfirmationPage } from './pages/ExpressBook/Step4/ExpressBookConfirmationPage';
import { NotFoundPage } from './pages/NotFound/NotFoundPage';
import { ViewOnlyMenu } from './pages/Menu/ViewOnlyMenu';
import { GoogleApiProvider } from './providers/GoogleApiProvider';
import { AvailabilityProvider } from './providers/AvailabilityProvider';
import { PostHogProvider } from './providers/PostHogProvider';
/*
 * Types.
 */

interface AppProps {}

/*
 * Constants.
 */

const pvEnv = process.env.REACT_APP_PV_ENV as string;
const API_HOST = apiHost(pvEnv);

/*
 * Components.
 */

const App: FC<AppProps> = (props: AppProps) => {
  return (
    <ErrorBoundary>
      <ServerStateProvider apiHost={API_HOST}>
        <ThemeProvider>
          <PostHogProvider>
            <GoogleApiProvider>
              <BrowserRouter>
                <AvailabilityProvider>
                  <Routes>
                    <Route element={<SearchPageLayout />}>
                      <Route path="/" element={<SearchResultsPage />} />
                      <Route
                        path="/cities/:city"
                        element={<SearchResultsPage />}
                      />
                    </Route>
                    <Route element={<MarketplaceLayout />}>
                      <Route
                        path="/organizations/:slug"
                        element={<OrganizationSpacesPage />}
                      />
                      <Route
                        path="/venues/:venueSlug"
                        element={<VenueSpacesPage />}
                      />
                      <Route
                        path="/venues/:venueSlug/menu"
                        element={<ViewOnlyMenu />}
                      />
                      <Route
                        path="/venues/:venueSlug/spaces/:spaceSlug"
                        element={<SpacePage />}
                      />
                      <Route path="not-found" element={<NotFoundPage />} />
                    </Route>
                    <Route
                      path="/venues/:venueSlug/spaces/:spaceSlug/bookings/new"
                      element={<VenueBookingsPage />}
                    />
                    <Route
                      path="/venues/:venueSlug/spaces/:spaceSlug/bookings/request-sent"
                      element={<MarketplaceEventSuccessPage />}
                    />
                    <Route
                      path="/venues/:venueSlug/spaces/:spaceSlug/bookings/:eventSlug"
                      element={<SelectMenuPage />}
                    />
                    <Route
                      path="/venues/:venueSlug/spaces/:spaceSlug/bookings/:eventSlug/checkout"
                      element={<ExpressBookCheckoutPage />}
                    />

                    <Route
                      path="/venues/:venueSlug/spaces/:spaceSlug/bookings/:eventSlug/success"
                      element={<ExpressBookConfirmationPage />}
                    />
                    <Route path="/*" element={<Navigate to="/" />} />
                  </Routes>
                </AvailabilityProvider>
              </BrowserRouter>
            </GoogleApiProvider>
          </PostHogProvider>
        </ThemeProvider>
      </ServerStateProvider>
    </ErrorBoundary>
  );
};

export default App;
