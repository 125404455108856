import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { NavigationBar } from '../NavigationBar';
import { useCaptureUTMParams } from '../../utils/attribution_tracking';

/*
 * Types.
 */

interface MarketplaceLayoutProps {}

/*
 * Constants.
 */

const StyledOutletDiv = styled.div``;

const PageContainer = styled.div`
  background-color: white;
`;

/*
 * Component.
 */

export const MarketplaceLayout: FC<MarketplaceLayoutProps> = () => {
  useCaptureUTMParams();
  return (
    <PageContainer>
      <NavigationBar sx={{ boxShadow: 'none' }} />
      <StyledOutletDiv>
        <Outlet />
      </StyledOutletDiv>
    </PageContainer>
  );
};
