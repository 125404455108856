import React from 'react';
import { Helmet } from 'react-helmet';
import { useSearchResults } from './providers/SearchResultsProvider';
import { useMappableVenues } from './providers/MappableVenuesProvider';

export const SearchPageSeoMarkup = () => {
  const { locationQuery } = useSearchResults();
  const { mappableVenues } = useMappableVenues();
  const title = `Perfect Venue Marketplace - ${locationQuery}`;
  const description = `Find the perfect venue in ${locationQuery}. Browse private event venues and spaces for rent for your next event, party, or meeting.`;
  const url = `https://book.perfectvenue.com/cities/${locationQuery.replace(' ', '-')}`;
  return (
    <Helmet>
      <title>Perfect Venue Marketplace</title>
      <title>Event Venues in {locationQuery} | Find Private Event Spaces</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content="en_US" />

      <link rel="canonical" href={url} />
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'SearchResultsPage',
          mainEntity: {
            '@type': 'ItemList',
            numberOfItems: mappableVenues.length,
            itemListElement: mappableVenues.map((venue) => ({
              '@type': 'Place',
              name: venue.name,
              geo: {
                '@type': 'GeoCoordinates',
                latitude: venue.position.lat,
                longitude: venue.position.lng,
              },
            })),
          },
          about: {
            '@type': 'City',
            name: locationQuery,
          },
        })}
      </script>
    </Helmet>
  );
};
