import { isNil } from 'lodash';
import { Dayjs } from 'dayjs';

import type { PartialAvailableSpacesRequest } from '../../types/models/space_types';
import type { Nullable } from '../../types/common';
import { guestRangeValuesMap } from '../../types/components/filter_bar_types';
import type {
  EventStyle,
  GuestRange,
} from '../../types/components/filter_bar_types';

/*
 * Types.
 */

interface BuildAvailableSpacesParams {
  organizationSlug?: string;
  venueSlug?: string;
  startDate: Nullable<Dayjs>;
  startOffset: Nullable<number>;
  endOffset: Nullable<number>;
  eventStyle: EventStyle;
  guestRange: GuestRange;
}

/*
 * Helpers.
 */

export const buildAvailableSpacesRequest = (
  params: BuildAvailableSpacesParams,
) => {
  const {
    organizationSlug,
    venueSlug,
    startDate,
    startOffset,
    endOffset,
    eventStyle,
    guestRange,
  } = params;

  const req: PartialAvailableSpacesRequest = {
    organizationSlug: organizationSlug,
    venueSlug: venueSlug,
  };

  if (!isNil(startDate)) {
    req.startDate = startDate.toDate();
  }

  if (!isNil(startOffset)) {
    req.startOffset = startOffset;
  }

  if (!isNil(endOffset)) {
    req.endOffset = endOffset;
  }

  if (eventStyle === 'seated') {
    req.seated = guestRangeValuesMap[guestRange];
  } else if (eventStyle === 'standing') {
    req.standing = guestRangeValuesMap[guestRange];
  } else {
    req.seated = guestRangeValuesMap[guestRange];
    req.standing = guestRangeValuesMap[guestRange];
  }

  return req;
};
