import * as React from 'react';

import { styled as muiStyled } from '@mui/material/styles';
import { TextField, Autocomplete, Grid, Typography } from '@mui/material';

import { MapPin } from 'react-feather';

import { debounce } from '@mui/material/utils';

const autocompleteService = { current: null };

interface PlaceType {
  description: string;
}

const StyledLocationTextField = muiStyled(TextField)({
  input: {
    cursor: 'pointer',
  },

  'input::selection': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  '&:hover + .HoverOverlay': {
    opacity: '0.2',
  },
  '&:focus-within + .HoverOverlay': {
    opacity: '0.2',
  },
});
interface LocationAutocompleteProps {
  locationQuery: string;
  onChange: (searchString: string) => void;
  styleProps?: any;
  size?: 'small' | 'medium';
}
export const LocationAutocomplete = function ({
  locationQuery,
  onChange,
  styleProps,
  size = 'medium',
}: LocationAutocompleteProps) {
  const [value, setValue] = React.useState<PlaceType | null>({
    description: locationQuery,
  });
  const [inputValue, setInputValue] = React.useState(locationQuery);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);

  React.useEffect(() => {
    setValue({ description: locationQuery });
    setInputValue(locationQuery);
  }, [locationQuery]);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            {
              ...request,
              types: ['(cities)'], // Restrict to cities
              region: 'US',
              componentRestrictions: { country: ['us', 'ca', 'mx'] }, // Restrict to US, CA, and MX
            },
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-place-search"
      sx={{
        'root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
          top: '15px',
          right: '15px',
        },
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      size={size}
      includeInputInList
      filterSelectedOptions
      value={value}
      fullWidth
      forcePopupIcon={false}
      noOptionsText="Enter a location"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue?.description || '');
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <StyledLocationTextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label="Where"
          fullWidth
          placeholder="Anywhere"
          variant="outlined"
          sx={{ ...styleProps }}
        />
      )}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;

        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <MapPin />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Typography variant="body2" color="text.secondary">
                  {option.description}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
