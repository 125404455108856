import React from 'react';
import { Dayjs } from 'dayjs';
import {
  DesktopDatePicker as MuiDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { Calendar as CalendarIcon } from 'react-feather';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { PVBlack } from '@pv/common/colors';
import type { HoursOfOperation } from '../../../../types/models/venue_types';
import { useShouldDisableDate } from '../../../../helpers/dateHelpers';
import { Nullable } from '../../../../types/common';
export const DatePicker = ({
  hoursOfOperation,
  minLeadTimeDays,
  maxLeadTimeDays,
  open,
  onOpen,
  onClose,
  value,
  onChange,
}: {
  hoursOfOperation: HoursOfOperation;
  minLeadTimeDays: number;
  maxLeadTimeDays: number;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  value?: Nullable<Dayjs>;
  onChange: (val: Nullable<Dayjs>) => void;
}) => {
  const shouldDisableDate = useShouldDisableDate(
    hoursOfOperation,
    minLeadTimeDays,
    maxLeadTimeDays,
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        label="When"
        name="date"
        value={value}
        onChange={onChange}
        shouldDisableDate={shouldDisableDate}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        slotProps={{
          field: {
            readOnly: true,
          },
          textField: {
            onClick: onOpen,
            placeholder: 'Select Date',
          },
          inputAdornment: {
            position: 'start',
            sx: {
              marginRight: '0px',
              svg: {
                color: PVBlack,
              },
            },
          },
        }}
        format="MMMM DD, YYYY"
      />
    </LocalizationProvider>
  );
};
