import React from 'react';
import { Section, SectionHeader } from './styledComponents';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material';
import { SanitizedHtml } from '@pv/common/components';
import { X as CloseIcon } from 'react-feather';

interface PoliciesProps {
  showPolicyModal: boolean;
  setShowPolicyModal: (value: boolean) => void;
  venuePolicy: string;
}

export const Policies = ({
  showPolicyModal,
  setShowPolicyModal,
  venuePolicy,
}: PoliciesProps) => {
  return (
    <>
      <Section>
        <SectionHeader>Policies</SectionHeader>
        <Box
          sx={{
            maxHeight: '192px',
            overflowY: 'hidden',
          }}
        >
          <SanitizedHtml html={venuePolicy} />
        </Box>
        <span>
          <Link
            component="button"
            variant="body2"
            onClick={() => setShowPolicyModal(true)}
          >
            View More
          </Link>
        </span>
      </Section>
      <Dialog open={showPolicyModal} onClose={() => setShowPolicyModal(false)}>
        <DialogTitle>
          Policies
          <Button
            onClick={() => setShowPolicyModal(false)}
            sx={{
              position: 'absolute',
              right: '8px',
              top: '8px',
            }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <SanitizedHtml html={venuePolicy} />
        </DialogContent>
      </Dialog>
    </>
  );
};
