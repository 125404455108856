import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCaptureUTMParams = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const utmSource = searchParams.get('utm_source');
    const utmMedium = searchParams.get('utm_medium');
    const utmCampaign = searchParams.get('utm_campaign');

    if (utmSource) {
      localStorage.setItem('utm_source', utmSource);
    }
    if (utmMedium) {
      localStorage.setItem('utm_medium', utmMedium);
    }
    if (utmCampaign) {
      localStorage.setItem('utm_campaign', utmCampaign);
    }
  }, [location]);
};

export const retrieveSavedUTMParams = () => {
  const utmSource = localStorage.getItem('utm_source');
  const utmMedium = localStorage.getItem('utm_medium');
  const utmCampaign = localStorage.getItem('utm_campaign');

  return { utmSource, utmMedium, utmCampaign };
};

export const useSetUtmSourceToMarketplace = () => {
  const utmSource = 'PV Marketplace';

  useEffect(() => {
    localStorage.setItem('utm_source', utmSource);
  }, [utmSource]);
};

export const addUtmParams = (url: string) => {
  const { utmSource, utmMedium, utmCampaign } = retrieveSavedUTMParams();
  const urlObj = new URL(url);
  if (utmSource) {
    urlObj.searchParams.set('utm_source', utmSource);
  }
  if (utmMedium) {
    urlObj.searchParams.set('utm_medium', utmMedium);
  }
  if (utmCampaign) {
    urlObj.searchParams.set('utm_campaign', utmCampaign);
  }
  return urlObj.toString();
};
