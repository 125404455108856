import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { NavigationBar } from './NavigationBar';
import {
  useCaptureUTMParams,
  useSetUtmSourceToMarketplace,
} from '../../../utils/attribution_tracking';

export const SearchPageLayout = () => {
  useCaptureUTMParams();
  useSetUtmSourceToMarketplace();
  return (
    <Box sx={{ backgroundColor: 'white' }}>
      <NavigationBar />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
};
