import React, { FC } from 'react';
import { Button } from '@mui/material';
import { MessageCircle } from 'react-feather';

import { Nullable } from '../types/common';
import { addUtmParams } from '../utils/attribution_tracking';

/*
 * Types.
 */

interface ContactUsButtonProps {
  leadFormUrl: Nullable<string>;
  disabled?: boolean;
}

/*
 * Components.
 */

export const ContactUsButton: FC<ContactUsButtonProps> = ({
  leadFormUrl,
  disabled = false,
}: ContactUsButtonProps) => {
  if (!leadFormUrl) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<MessageCircle />}
      sx={{ padding: '12px 28px', fontSize: '16px' }}
      disabled={disabled}
      href={addUtmParams(leadFormUrl)}
      target="_blank"
      rel="noopener noreferrer"
    >
      Contact Us
    </Button>
  );
};
