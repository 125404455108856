import React, { createContext, useContext, useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import type { Nullable } from '../types/common';
import type {
  EventStyle,
  GuestRange,
} from '../types/components/filter_bar_types';

const setItemWithExpiry = (key: string, value: string, ttl: number) => {
  localStorage.setItem(key, value);
  localStorage.setItem(
    key + '_expiry',
    (new Date().getTime() + ttl).toString(),
  );
};

const getItemWithExpiry = (key: string) => {
  const itemStr = localStorage.getItem(key);
  const expiryStr = localStorage.getItem(key + '_expiry');
  if (!itemStr || !expiryStr) return null;

  if (new Date().getTime() > parseInt(expiryStr)) {
    localStorage.removeItem(key);
    return null;
  }
  return itemStr;
};
interface AvailabilityContextType {
  startDate: Nullable<Dayjs>;
  startOffset: Nullable<number>;
  endOffset: Nullable<number>;
  setStartDate: (date: Nullable<Dayjs>) => void;
  setStartOffset: (offset: Nullable<number>) => void;
  setEndOffset: (offset: Nullable<number>) => void;
  origin: Nullable<string>;
  setOrigin: (origin: Nullable<string>) => void;
  eventStyle: EventStyle;
  setEventStyle: (eventStyle: EventStyle) => void;
  guestRange: GuestRange;
  setGuestRange: (guestRange: GuestRange) => void;
}

const AvailabilityContext = createContext<AvailabilityContextType | undefined>(
  undefined,
);

export const AvailabilityProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [startDate, setStartDate] = useState<Nullable<Dayjs>>(
    getItemWithExpiry('startDate')
      ? dayjs(getItemWithExpiry('startDate'))
      : null,
  );
  const [startOffset, setStartOffset] = useState<Nullable<number>>(
    getItemWithExpiry('startOffset')
      ? parseInt(getItemWithExpiry('startOffset') || '0')
      : null,
  );
  const [endOffset, setEndOffset] = useState<Nullable<number>>(
    getItemWithExpiry('endOffset')
      ? parseInt(getItemWithExpiry('endOffset') || '0')
      : null,
  );
  const [origin, setOrigin] = useState<Nullable<string>>(
    getItemWithExpiry('origin') || 'marketplace',
  );
  const [eventStyle, setEventStyle] = useState<EventStyle>(
    (getItemWithExpiry('eventStyle') as EventStyle) || 'seated_or_standing',
  );
  const [guestRange, setGuestRange] = useState<GuestRange>(
    (getItemWithExpiry('guestRange') as GuestRange) || 'any_number',
  );

  useEffect(() => {
    const saveFor = 1000 * 60 * 60; // 1 hour
    setItemWithExpiry('startDate', startDate?.toISOString() || '', saveFor);
    setItemWithExpiry('startOffset', startOffset?.toString() || '', saveFor);
    setItemWithExpiry('endOffset', endOffset?.toString() || '', saveFor);
    setItemWithExpiry('origin', origin || '', saveFor);
    setItemWithExpiry('eventStyle', eventStyle, saveFor);
    setItemWithExpiry('guestRange', guestRange, saveFor);
  }, [startDate, startOffset, endOffset, origin, eventStyle, guestRange]);
  return (
    <AvailabilityContext.Provider
      value={{
        startDate,
        startOffset,
        endOffset,
        setStartDate,
        setStartOffset,
        setEndOffset,
        origin,
        setOrigin,
        eventStyle,
        setEventStyle,
        guestRange,
        setGuestRange,
      }}
    >
      {children}
    </AvailabilityContext.Provider>
  );
};

export const useAvailability = () => {
  const context = useContext(AvailabilityContext);
  if (context === undefined) {
    throw new Error(
      'useAvailability must be used within an AvailabilityProvider',
    );
  }
  return context;
};
